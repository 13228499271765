import * as React from "react";
import { Link, graphql } from "gatsby";
import Layout from "../components/Layout";
import { GatsbyImage, getImage, getSrc } from "gatsby-plugin-image";
import Seo from "../components/Seo";
// import { useTranslation } from "gatsby-plugin-react-i18next";

const FormatRoute = ({ data }) => {
	// const { t } = useTranslation();
	const formatMeta = data.formatMain.frontmatter;
	const articles = data.allMarkdownRemark.edges;
	const articleRoll = articles.map((article) => (
		<Link
			to={article.node.fields.slug}
			className="group p-4 block col-span-2 md:col-span-1 mb-4"
		>
			<GatsbyImage
				image={getImage(article.node.frontmatter.featuredimage)}
				className="rounded transition grayscale group-hover:grayscale-0 aspect-video"
			/>
			<h3 className="text-2xl my-2 text leading-none">
				{article.node.frontmatter.title}
			</h3>
			<p className="text-basis text-stone-600">{article.node.frontmatter.description}</p>
		</Link>
	));
	// const format = formatMeta.title;
	// const totalCount = data.allMarkdownRemark.totalCount;
	// const formatHeader = `${totalCount} ${t("common:format.topic")} “${format}”`;

	return (
		<Layout>
			<section className="section">
				<Seo
					title={"Format: " + formatMeta.title}
					description={formatMeta.formatdescription}
					image={getSrc(formatMeta.featuredimage)}
					article={false}
				/>
				<div className="heroImageGroup relative group">
					<div className="aspect-[3/2] md:aspect-[8/3] brightness-75 transition group-hover:saturate-50  bg-no-repeat bg-center bg-fixed bg-cover">
						<GatsbyImage
							image={getImage(formatMeta.featuredimage)}
							className="h-full"
						/>
					</div>

					<div className="absolute bottom-5 md:bottom-[20%] lg:bottom-5 xl:bottom-[20%]  text-white w-full">
						<div className="gecko-wrapper">
							<h1 className="lg:text-6xl text-xl uppercase bg-black leading-tight md:text-4xl w-full md:w-2/3 font-sans lg:py-4 pl-4 transition">
								{formatMeta.title}
							</h1>
						</div>
					</div>
				</div>

				<div className="section about-gecko-section bg-stone-200 w-full mb-8">
					<div className="prose text-sm md:text-xl gecko-wrapper py-2 lg:py-10">
						{/* <Markdown>{formatMeta.formatdescription}</Markdown> */}
					</div>
				</div>

				<div className="articleRoll-wrapper gecko-wrapper">
					{/* <h1 className="uppercase text-center text-2xl py-8 text-stone-400">
						{formatHeader}
					</h1> */}
					<div className="articleRoll grid grid-cols-2 gap-4">
						{articleRoll}
					</div>
				</div>
			</section>
		</Layout>
	);
};

export default FormatRoute;

export const formatPageQuery = graphql`
	query FormatPage($format: String!, $slug: String!, $language: String!) {
		locales: allLocale(filter: { language: { eq: $language } }) {
			edges {
				node {
					ns
					data
					language
				}
			}
		}
		formatMain: markdownRemark(frontmatter: { title: { eq: $format } }) {
			frontmatter {
				title
				formatdescription
				featuredimage {
					childImageSharp {
						gatsbyImageData(
							width: 2000
							quality: 50
							webpOptions: { quality: 70 }
							layout: CONSTRAINED
						)
					}
				}
			}
		}
		allMarkdownRemark(
			filter: {
				frontmatter: { format: { eq: $slug } }
				fields: { language: { eq: $language }, langAvailable: {eq: true}  }
			}
			sort: { fields: [frontmatter___date], order: DESC }
		) {
			totalCount
			edges {
				node {
					fields {
						slug
					}
					frontmatter {
						title
						featuredimage {
							childImageSharp {
								gatsbyImageData(
									width: 800
									quality: 50
									webpOptions: { quality: 70 }
								)
							}
						}
						translationStatus
						description
					}
				}
			}
		}
	}
`;
